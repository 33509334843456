export default {
  Dashboards: 'Tableaux de bord',
  CRM: 'CRM',
  Analytics: 'Analytique',
  eCommerce: 'commerce électronique',
  Calendar: 'Calendrier',
  Chat: 'Discuter',
  Email: 'E-mail',
  Invoice: 'Facturer',
  List: 'Lister',
  Preview: 'Aperçu',
  Edit: 'Éditer',
  Add: 'Ajouter',
  User: 'Utilisateur',
  'User List': "liste d'utilisateur",
  'User View': 'Vue utilisateur',
  'APPS AND PAGES': 'APPLICATIONS ET PAGES',
  Pages: 'Pages',
  Authentication: 'Authentification',
  'Login V1': 'Connexion V1',
  'Login V2': 'Connexion V2',
  'Register V1': "S'inscrire V1",
  'Register V2': "S'inscrire V2",
  'Forgot Password V1': 'Mot de passe oublié V1',
  'Forgot Password V2': 'Mot de passe oublié V2',
  'Reset Password V1': 'Réinitialiser le mot de passe V1',
  'Reset Password V2': 'Réinitialiser le mot de passe V2',
  Miscellaneous: 'Divers',
  'Under Maintenance': 'En maintenance',
  Error: 'Erreur',
  'Coming Soon': 'Bientôt disponible',
  'Not Authorized': 'Pas autorisé',
  'Knowledge Base': 'Base de connaissances',
  'Account Settings': 'Paramètres du compte',
  Pricing: 'Prix',
  FAQ: 'FAQ',
  'USER INTERFACE': 'INTERFACE UTILISATEUR',
  Typography: 'Typographie',
  Icons: 'Icônes',
  Gamification: 'Gamification',
  Cards: 'Cartes',
  Basic: 'De base',
  Statistics: 'Statistiques',
  Advance: 'Avance',
  Actions: 'Actions',
  Chart: 'Graphique',
  Components: 'Composants',
  Alert: 'Alerte',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Bouton',
  Menu: 'Menu',
  'Expansion Panel': "Panneau d'extension",
  Dialog: 'Dialogue',
  Pagination: 'Pagination',
  Tabs: 'Onglets',
  Tooltip: 'Info-bulle',
  Chip: 'Ébrécher',
  Snackbar: 'Snack-bar',
  Stepper: 'Pas à pas',
  Timelines: 'Chronologie',
  Treeview: 'Arborescence',
  'FORMS & TABLES': 'FORMULAIRES & TABLEAUX',
  'Forms Elements': 'Éléments de formulaires',
  Autocomplete: 'Saisie automatique',
  Checkbox: 'Case à cocher',
  Combobox: 'Boîte combo',
  'File Input': 'Entrée de fichier',
  Radio: 'Radio',
  'Range Slider': 'Curseur de plage',
  Select: 'Sélectionner',
  Slider: 'Glissière',
  Switch: 'Changer',
  Textarea: 'Zone de texte',
  Textfield: 'Champ de texte',
  'Date Picker': 'Sélecteur de date',
  'Month Picker': 'Sélecteur de mois',
  'Time Picker': 'Sélecteur de temps',
  Rating: 'Notation',
  'Form Layout': 'Disposition du formulaire',
  'Form validation': 'Validation du formulaire',
  Tables: 'les tables',
  'Simple Table': 'Tableau simple',
  Datatable: 'Table de données',
  'CHARTS AND MAPS': 'CARTES ET CARTES',
  Charts: 'Graphiques',
  'Apex Chart': 'Graphique Apex',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Dépliant Cartes',
  Others: 'Autres',
  'Access Control': 'Controle d\'Acces',
  'Menu Levels': 'Niveaux de menu',
  'Menu Level 2.1': 'Niveau de menu 2.1',
  'Menu Level 2.2': 'Niveau de menu 2.2',
  'Menu Level 3.1': 'Menu Niveau 3.1',
  'Menu Level 3.2': 'Niveau de menu 3.2',
  'Disabled Menu': 'Menu désactivé',
  'Raise Support': 'Augmenter le soutien',
  Documentation: 'Documentation',
  'Charts & Maps': 'Graphiques et cartes',
  Apps: 'Applications',
  Hubspot: 'Hubspot',
  Projects: 'Projets',
  PROJECTS: 'PROJETS',
  'Project List': 'Liste de projets',
  'User Interface': 'Interface utilisateur',
  'Forms & Tables': 'Formulaires et tableaux',
  Bidder: 'Bidder',
  SALES: 'VENTES',
  SURVEY: 'SURVEY',
  'Survey Builder': 'Survey Builder',
  'User\'s Timeline': 'Timeline Utilisateur',
  'Team\'s Timeline': 'Timeline Equipe',
  'Fields Timeline': 'Timeline Terrain',
  'Office Timeline': 'Office Timeline',
  'Today': 'Aujourd\'hui',
  'TEAMS': 'TEAMS',
  'Members': 'Members',
  'Sales & Revenue': 'Sales & Revenue',
  'CSAT': 'CSAT',
  'Purchase Orders': 'Purchase Orders',
  'ADMIN RESOURCES': 'ADMIN RESOURCES',
  'Suppliers': 'Suppliers',
  'OTHER': 'AUTRE',
  'EXTERNAL': 'EXTERNAL',
  'ChatGPT': 'ChatGPT',
  'Viz Var Check': 'Viz Var Check',
  'Sage': 'Sage',
  'Hubspot Dash': 'Hubspot Dash',
  'COURSES': 'COURSES',
  'Dashboard': 'Dashboard',
  'Course Details': 'Course Details',
  'Courses': 'Courses',
  'ACADEMY': 'ACADEMY',
  'Forsta Tools':'Forsta Tools'
}
